import React from "react"

import TruckIcon from "../../images/icon-truck.svg"
import ShipIcon from "../../images/icon-ship.svg"

export default function ServiceList(props) {
  return (
    <div className="inline-flex bg-white mt-8 p-4 lg:p-8 rounded-full text-left text-sm lg:text-lg">
      <div
        className="inline-flex px-2 lg:px-4"
        data-sal="slide-left"
        data-sal-duration="1200"
      >
        <TruckIcon className="w-11 h-11" />
        <div className="flex flex-col px-2 lg:px-4">
          <span className="font-bold text-xl leading-6">Road</span>
          <span className="font-light text-lg leading-4">Transportation</span>
        </div>
      </div>
      <div
        className="inline-flex px-2 lg:px-4"
        data-sal="slide-left"
        data-sal-duration="1200"
      >
        <ShipIcon className="w-11 h-11" />
        <div className="flex flex-col px-2 lg:px-4">
          <span className="font-bold text-xl leading-6">Sea</span>
          <span className="font-light text-lg leading-4">Transportation</span>
        </div>
      </div>
    </div>
  )
}
