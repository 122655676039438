import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GoogleMapReact from "google-map-react"
import scrollTo from "gatsby-plugin-smoothscroll"

import CargoIcon from "../images/icon-cargo.svg"
import FreightIcon from "../images/icon-freight.svg"
import WarehouseIcon from "../images/icon-warehouse.svg"
import CustomIcon from "../images/icon-custom.svg"
import TruckIcon from "../images/icon-truck.svg"
import ShipIcon from "../images/icon-ship.svg"
import EmailIcon from "../images/icon-email.svg"
import PhoneIcon from "../images/icon-phone.svg"
import FaxIcon from "../images/icon-fax.svg"
import PinIcon from "../images/icon-pin.svg"
import MenuIcon from "../images/icon-menu.svg"
import ToTopIcon from "../images/icon-to-top.svg"

import ServiceBox from "./atoms/serviceBox"
import ContactForm from "./sections/contactForm"

export default function MobileView(props) {
  const [showMenu, setShowmenu] = React.useState(false)

  const actions = {
    scrollTo(l) {
      scrollTo(l)
      setShowmenu(false)
    },
  }

  return (
    <div className="block lg:hidden">
      <header id="m-header" className="w-screen h-screen bg-gray-100">
        <div className="absolute inset-0 block w-screen h-screen">
          <StaticImage
            src="../images/bgimg-cover.png"
            formats={["auto", "webp", "avif"]}
            alt="VLog Trans Hero Image"
            className="absolute inset-0 w-screen h-screen"
          />
        </div>
        <div className="absolute flex flex-col inset-0 w-screen h-screen px-6 z-40">
          <div className="flex flex-row justify-between relative pt-8">
            <MenuIcon
              onClick={() => setShowmenu(true)}
              className="inline-block w-8 h-8 my-2"
            />
            <StaticImage
              placeholder="blurred"
              src="../images/logo.png"
              formats={["auto", "webp", "avif"]}
              alt="VLog Trans Logo"
              loading="eager"
              className="inline-block w-12 h-12"
            />
          </div>
          <div className="flex flex-col justify-center flex-1">
            <div className="flex flex-col text-left text-white relative z-40">
              <span
                class="text-lg py-4 font-black headline"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-easing="ease"
              >
                VLOG TRANS
              </span>
              <h1
                className="text-5xl font-black headline"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                Your
                <br /> Satisfaction
                <br /> Is Our <br />
                Priority
              </h1>
            </div>
            <div className="inline-flex bg-white text-dark mt-8 p-4 justify-center align-center rounded-full text-sm relative z-40 w-full">
              <div
                className="inline-flex px-2"
                data-sal="slide-left"
                data-sal-duration="1200"
              >
                <TruckIcon className="w-9 h-9" />
                <div className="flex flex-col justify-center px-2">
                  <span className="font-bold text-md leading-4">Road</span>
                  <span className="font-light text-xs leading-4">
                    Transportation
                  </span>
                </div>
              </div>
              <div
                className="inline-flex px-2"
                data-sal="slide-left"
                data-sal-duration="1200"
              >
                <ShipIcon className="w-9 h-9" />
                <div className="flex flex-col justify-center px-2">
                  <span className="font-bold text-md leading-4">Sea</span>
                  <span className="font-light text-xs leading-4">
                    Transportation
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="m-about" className="min-h-screen bg-gray-100">
        <div className="relative z-40 text-left px-6 py-16 bg-gray-100 rounded-4xl">
          <span
            class="text-lg font-black headline"
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            VLOG TRANS
          </span>
          <h2
            className="text-5xl font-black headline mt-4"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            Reliable Logistics Service
          </h2>
          <p
            className="text-base py-4"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            Since opening our doors, we’ve been committed to providing service
            of the highest quality, paying particular attention to working
            efficiently while keeping the lines of communication with our
            clients clear and concise.
          </p>
          <p
            className="text-base py-4"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Our mission at Vlog Trans is simple: to provide high-quality
            services in a timely manner. Our team caters to each project’s
            specific needs to ensure excellence. We hope you’ll find what you’re
            looking for. For more information or general inquiries, feel free to
            get in touch today.
          </p>
        </div>
        <StaticImage
          src="../images/bgimg-ship-1.png"
          formats={["auto", "webp", "avif"]}
          alt="VLog Trans"
          className="-mt-16 z-10"
        />
      </section>

      <section id="m-services" className="bg-gray-100 min-h-screen">
        <div className="relative">
          {/* <div className="absolute block w-full h-full"> */}
            <StaticImage
              src="../images/bgimg-ship-2.png"
              formats={["auto", "webp", "avif"]}
              alt="VLog Trans"
              className="w-full"
            />
          {/* </div> */}

          <div className="absolute top-0 block w-full h-full flex flex-col justify-between z-40">
            {/* Text */}
            <div className="relative flex flex-col items-center justify-center px-4 pt-8 text-center text-white">
              <span className="text-lg font-black headline">Service</span>
              <h2
                className="text-5xl font-black headline mt-4"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                What We <br /> Provide
              </h2>
            </div>
            {/* End of text */}

            {/* Black bar */}
            <div className="relative bg-black bg-opacity-80 backdrop-blur-md text-white w-full inline-flex justify-center p-6 rounded-3xl text-left text-sm">
              <div
                className="inline-flex px-2"
                data-sal="slide-left"
                data-sal-duration="1200"
              >
                <TruckIcon className="w-9 h-9" />
                <div className="flex flex-col justify-center px-4">
                  <span className="font-bold text-md leading-4">Road</span>
                  <span className="font-light text-xs leading-4">
                    Transportation
                  </span>
                </div>
              </div>
              <div
                className="inline-flex px-2"
                data-sal="slide-left"
                data-sal-duration="1200"
              >
                <ShipIcon className="w-9 h-9" />
                <div className="flex flex-col justify-center px-4">
                  <span className="font-bold text-md leading-4">Sea</span>
                  <span className="font-light text-xs leading-4">
                    Transportation
                  </span>
                </div>
              </div>
            </div>
            {/* End of black bar */}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 p-6">
          <ServiceBox
            icon={CargoIcon}
            label="Shipment"
            title="Cargo Insurance"
            desc="FCL / LCL"
          />
          <ServiceBox
            icon={FreightIcon}
            label="Export & Import Booking"
            title="Freight Services"
            desc="FCL / LCL"
          />
          <ServiceBox
            icon={WarehouseIcon}
            label="Bonded / Non Bonded (Johor)"
            title="Warehouse"
            desc="Transloading & Storage"
          />
          <ServiceBox
            icon={CustomIcon}
            label="Type K1 / K2 / K3 / K4 / K8 / K9 / ZB1 / ZB2"
            title="Custom Brokerage"
            desc={
              <>
                <span className="block font-bold mb-2">Johor Bahru</span>
                <span className="block mb-2">T. Puteri 1st Link</span>
                <span className="block mb-2">T. Kupang 2nd Link</span>
                <span className="block mb-2">Johor Port (Pasir Gudang)</span>
                <span className="block mb-2">Port of Tanjung Pelepas</span>
                <span className="block mb-2">Senai Airport</span>
              </>
            }
          />
        </div>
      </section>

      <section id="m-contact-form" className="bg-blue-500 min-h-screen">
        <div className="px-6 py-16 max-w-xl mx-auto">
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <h2 className="text-3xl text-white font-black headline">
              Get In Touch
            </h2>
            <p className="text-xl text-white mb-8 font-light">
              Don’t Hesitate to Email Us
            </p>
          </div>
          <ContactForm />
        </div>
      </section>

      <section
        id="m-contact-info"
        className="bg-gray-200 text-gray-800 min-h-screen relative"
      >
        <div className="px-6 pt-16 pb-8 max-w-2xl mx-auto">
          <h2
            className="text-4xl font-black headline"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            Contact
          </h2>
          <p
            className="text-lg font-black headline mt-12"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            VLOG TRANS SDN BHD
          </p>
          <p
            className="italic text-base font-light mb-8"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            Working Hour: 9am -6pm Mon-Fri
          </p>

          <div
            class="flex mb-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div class="flex-shrink-0 mr-3 py-1 w-8">
              <EmailIcon className="w-6 mx-auto" />
            </div>
            <div class="flex-1 leading-relaxed">
              <a href="mailto:management@vlogtransport.com">
                management@vlogtransport.com
              </a>
            </div>
          </div>
          <div
            class="flex mb-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div class="flex-shrink-0 mr-3 py-1 w-8">
              <PhoneIcon className="w-5 mx-auto" />
            </div>
            <div class="flex-1 leading-relaxed">
              <a href="tel:6075590116">+(60)7-559 0116</a>
              <br />
              <a href="tel:6075506771">+(60)7-550 6771</a>
            </div>
          </div>

          <div
            class="flex mb-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div class="flex-shrink-0 mr-3 py-1 w-8">
              <FaxIcon className="w-6 mx-auto" />
            </div>
            <div class="pt-2 flex-1 leading-relaxed">
              <a href="fax:6075502113">+(60) 7-550 2113 (FAX)</a>
            </div>
          </div>

          <div
            class="flex"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div class="flex-shrink-0 mr-3 py-1 w-8">
              <PinIcon className="w-5 mx-auto" />
            </div>
            <div class="pt-2 flex-1 leading-relaxed">
              <p>
                125, Jalan Seri Impian 1, Taman Impian Emas, 81300 Skudai,
                Johor, Malaysia.
              </p>
            </div>
          </div>
        </div>

        <div className="px-6 pb-8 h-40 w-full rounded-4xl overflow-hidden">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyA4ot9lFQtujqh88xLOYEWRatVQNCTzjyw",
            }}
            defaultCenter={{
              lat: 1.5467699,
              lng: 103.6826994,
            }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals={true}
            // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            <div lat={1.5467699} lng={103.6826994} text="My Marker">
              <StaticImage
                src="../images/logo.png"
                formats={["auto", "webp", "avif"]}
                alt="VLog Trans Logo"
                className="w-8 h-8"
              />
            </div>
          </GoogleMapReact>
        </div>
        <div className="px-6">
          <p className="mt-2 text-lg font-black headline">Find Us Here</p>
          <p className="mt-4 text-xs">©2021 by Vlog Trans</p>
          <button
            className="absolute bottom-4 right-6"
            onClick={() => scrollTo("#m-header")}
          >
            <ToTopIcon className="w-12 h-12" />
          </button>
        </div>
      </section>
      {/* </Slider>
      </div> */}

      {showMenu && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50">
          <div className="bg-gray-200 rounded-3xl m-6 py-16 px-8 text-center">
            <span
              className="block cursor-pointer"
              onClick={() => actions.scrollTo("#m-header")}
            >
              Home
            </span>
            <span
              className="font-black text-4xl py-2 block cursor-pointer"
              onClick={() => actions.scrollTo("#m-about")}
            >
              About
            </span>
            <span
              className="font-black text-4xl py-2 block cursor-pointer"
              onClick={() => actions.scrollTo("#m-services")}
            >
              Services
            </span>
            <span
              className="font-black text-4xl py-2 block cursor-pointer"
              onClick={() => actions.scrollTo("#m-contact-form")}
            >
              Get In Touch
            </span>
            <span
              className="block cursor-pointer"
              onClick={() => actions.scrollTo("#m-contact-info")}
            >
              Contact
            </span>
            <div className="py-24">
              <span className="block py-4">Language</span>
              <span className="text-blue-300 font-black">EN</span>
            </div>
            <span>@{new Date().getFullYear()} by Vlog Trans</span>
          </div>
        </div>
      )}
    </div>
  )
}
